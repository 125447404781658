<script>
import {defineComponent} from 'vue';
import {LIST_GROUP} from "@/graphql/queries/groups/list";
import {CREATE_MESSAGE_MULTIPLE} from "@/graphql/mutations/message/createMessageMultiple";
import {useVuelidate} from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import {authComputed} from "@/state/helpers";
import {has, values} from "lodash";
import formatGraphQLErrors from "@/errors/formatGraphQLErrors";
import {notification} from "@/helpers/notifications";

export default defineComponent({
  name: "Message",
  data: () => ({
    error: null,
    submitted: false,
    groups: null,
    selectAll: false,
    selectedGroups: [],
    form: {
      groupIds: null,
      systemUserId: null,
      title: null,
      message: null,
      ip: null,
      node: null,
      joinMessage: false
    }
  }),
  apollo: {
    groups: {
      query: LIST_GROUP,
      loadingKey: 'loading'
    },
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        title: {
          required: helpers.withMessage("El titulo es requerido", required),
        },
        message: {
          required: helpers.withMessage("El mensaje es requerido", required),
        },
        node: {
          required: helpers.withMessage("El node es requerido", required),
        }
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.fillForm()
    }, 800);
  },
  methods: {
    async sendMessage() {
      try {
        this.submitted = true;
        this.v$.$touch();

        if (this.v$.$invalid) {
          return false;
        }

        const {data} = await this.$apollo.mutate({
          mutation: CREATE_MESSAGE_MULTIPLE,
          variables: {
            input: this.form
          }
        });

        const messages = data.messageMultiple;
        notification('success', `Se ha enviado mensajes a ${messages.length} grupos satisfactoriamente.`);
        this.resetForm();

        return messages;
      } catch (errors) {
        let dataError = has(errors, 'graphQLErrors') ? formatGraphQLErrors(errors.graphQLErrors) : errors.message

        if (has(dataError, 'category') && dataError.category === 'validation') {
          dataError = values(dataError.errors);
        }

        if (has(dataError, 'category') && dataError.category === 'user') {
          dataError = dataError.message
        }

        if (has(dataError, 'category') && dataError.category === 'graphql') {
          dataError = dataError.message
        }

        this.error = dataError;
        notification('error', dataError);
      }
    },
    fillForm() {
      this.form.systemUserId = this.currentUser?.id;
    },
    toggleAllCheckboxes() {
      if (this.selectAll) {
        this.selectedGroups = this.groups.map(item => item.id);
      } else {
        this.selectedGroups = [];
      }
    },
    resetForm() {
      this.form.title = null;
      this.form.message = null;
      this.form.ip = null;
      this.form.node = null;
      this.form.joinMessage = false;

      this.selectedGroups = [];
    },
  },
  watch: {
    selectedGroups: function (values) {
      if (values) {
        this.form.groupIds = values;
      }
    }
  },
  computed: {
    ...authComputed,
  }
});
</script>

<template>
  <div>
    <b-row class="mt-n4">
      <b-col lg="12">
        <b-card no-body class="mt-n4 mx-n0 mb-n0">
          <div class="bg-black">
            <b-card-body class="pb-3 mb-0">
              <b-row>
                <b-col md>
                  <b-row class="align-items-center">
                    <b-col md="auto">

                      <div class="avatar-md mb-md-0 mb-4">

                        <div class="avatar-title bg-red-custom rounded-circle">
                          <i class="lab las la-users text-white" style="font-size: 45px"></i>
                        </div>

                      </div>

                    </b-col>
                    <b-col md>
                      <h4 class="fw-semibold text-white text-uppercase">
                        Enviar mensaje a grupos
                      </h4>
                    </b-col>
                  </b-row>
                </b-col>

              </b-row>
            </b-card-body>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col xxl="7">
        <b-card no-body>
          <b-card-body class="p-4">

            <form
                autocomplete="off"
                id="send-message-group-form"
                class="needs-validation"
                @submit.prevent="sendMessage"
            >
              <b-row>
                <b-col lg="12">
                  <div class="mb-3">
                    <input
                        type="text"
                        class="form-control"
                        id="title"
                        placeholder="Titulo"
                        v-model="form.title"
                        :class="{
                          'is-invalid': submitted && v$.form.title.$error,
                        }"
                    >

                    <div v-for="(item, index) in v$.form.title.$errors" :key="index" class="invalid-feedback">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>

                  </div>

                  <div class="mb-3">
                    <textarea
                        class="form-control"
                        id="message"
                        rows="3"
                        v-model="form.message"
                        placeholder="Mensaje"
                        :class="{
                          'is-invalid': submitted && v$.form.message.$error,
                        }"
                    >
                    </textarea>

                    <div v-for="(item, index) in v$.form.message.$errors" :key="index" class="invalid-feedback">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>

                  <div class="mb-3">
                    <input
                        type="text"
                        class="form-control"
                        id="ip"
                        placeholder="IP"
                        v-model="form.ip"
                    >
                  </div>

                  <div class="mb-3">
                    <input
                        type="text"
                        class="form-control"
                        id="node"
                        placeholder="Nodo"
                        v-model="form.node"
                        :class="{
                          'is-invalid': submitted && v$.form.node.$error,
                        }"
                    >

                    <div v-for="(item, index) in v$.form.node.$errors" :key="index" class="invalid-feedback">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>

                  <div class="mb-3">
                    <div class="form-check form-switch form-switch-danger">
                      <input
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="joinMessage"
                          v-model="form.joinMessage"
                      >
                      <label class="form-check-label" for="joinMessage">
                        Desea Agrupar este mensaje con el anterior
                      </label>
                    </div>
                  </div>

                  <div class="invalid-feedback" v-if="error">
                    <span>{{ error }}</span>
                  </div>
                </b-col>

                <b-col lg="12" class="text-end">
                  <b-button
                      type="submit"
                      variant="customRed"
                  >
                    Enviar Mensaje
                  </b-button>
                </b-col>
              </b-row>
            </form>

          </b-card-body>

        </b-card>
      </b-col>

      <b-col xxl="5">
        <b-card no-body>
          <b-card-header class="align-items-center d-flex border-bottom-dashed">
            <b-card-title class="mb-0 flex-grow-1 text-center">Grupos</b-card-title>
          </b-card-header>

          <b-card-body>
            <div data-simplebar style="height: 635px;" class="mx-n3 px-3">
              <div class="vstack gap-3">

                <b-row>
                  <b-col xxl=12>
                    <div class="d-flex align-items-center">
                      <div class="flex-grow-1">
                        <div class="form-check">

                          <input
                              class="form-check-input"
                              type="checkbox"
                              id="allSelected"
                              v-model="selectAll"
                              @change="toggleAllCheckboxes"
                          >

                          <label class="form-check-label" for="allSelected">
                            Seleccionar / Deseleccionar todos
                          </label>

                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col
                      xxl=4
                      v-for="(group, key) in groups"
                      :key="key"
                  >
                    <div class="d-flex align-items-center">
                      <div class="flex-grow-1">
                        <div class="form-check">
                          <input
                              class="form-check-input"
                              type="checkbox"
                              :id="`group-${group.id}`"
                              :value="group.id"
                              v-model="selectedGroups"
                          >

                          <label class="form-check-label" :for="`group-${group.id}`">
                            {{ group.name }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>

              </div>
            </div>
          </b-card-body>
        </b-card>


      </b-col>
    </b-row>
  </div>
</template>

