<script>
import {defineComponent} from 'vue';
import appConfig from "../../../app.config.json";
import PageHeader from "@/components/page-header.vue";
import Layout from "@/layouts/main.vue";
import ContainerMessages from "@/views/message/container-messages.vue";

export default defineComponent({
  name: "ContainerMessage",
  page: {
    title: 'Mensajes',
    meta: [{name: "description", content: appConfig.description}]
  },
  components: {
    PageHeader,
    Layout,
    ContainerMessages
  },
  data: () => ({
    title: '',
    items: [
      {
        text: "Mensajes",
        href: "/",
      },
      {
        text: "Enviar mensajes",
        active: true,
      },
    ]
  }),
});
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>

    <ContainerMessages/>

  </Layout>
</template>
