<script>
import {defineComponent} from 'vue';
import SendMessage from "@/views/message/send-message.vue";

export default defineComponent({
  name: "ContainerMessages",
  components: {
    SendMessage
  }
});
</script>

<template>
  <SendMessage/>
</template>
