import gql from 'graphql-tag'

export const CREATE_MESSAGE_MULTIPLE = gql`
    mutation ($input: CreateMessageMultipleInput!) {
        messageMultiple(input: $input) {
            id
            title
            description
            typeOfMessage
            ip
            node
            joinMessage
            platform
            isWhatsapp
            createdAt
            systemUser {
                id
                name
                lastName
                motherLastName
                email
                profile
                status
            }
        }
    }
`
